export const e1Option = {
  textStyle: {
    fontSize: '30px'
  },
    grid: {
        top: '40px',
        bottom: '12%'
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar',
        barWidth: 30,
      }
    ]
  };

export const pieOption = {
    // grid: {
    //     top: '40px',
    //     bottom: '12%'
    // },
    // title: {
    //   text: 'Referer of a Website',
    //   subtext: 'Fake Data',
    //   left: 'center'
    // },
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c} ({d}%)'
    },
     
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        center: ['70%', '50%'],
        data: [
          // { value: 1048, name: 'Search' },
          // { value: 735, name: 'Direct' },
        ],
        label: {
          show: false,
        },
        itemStyle: {
          normal: {
            label: {
              show: false,
              color: '#fff',
            }
          }
        }
        // emphasis: {
        //   itemStyle: {
        //     shadowBlur: 0,
        //     shadowOffsetX: 0,
        //     shadowColor: 'rgba(0, 0, 0, 0.5)',
        //   }
        // }
      }
    ]
  };

export const Lineoption = {
    grid: {
        top: '40px',
        bottom: '12%'
    },
    legend: {
      data: ['总人数', '高风险']
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: [
      {
        type: 'value',
      },
      {
        type: 'value',
      }
    ],
    series: [
      {
        areaStyle: {
            // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //     {
            //     offset: 0,
            //     color: 'rgba(58,77,233,0.8)'
            //     },
            //     {
            //     offset: 1,
            //     color: 'rgba(58,77,233,0.3)'
            //     }
            // ])
            color: '#3adb73'
        },
        itemStyle : { normal: {label : {show: false, color: '#fff'}}},
        data: [],
        markPoint: {
          data:[
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ],
          itemStyle: {
            color: "#CD5359"
          },
          symbol: 'pin',
          symbolSize: 65,
          label: {
            color: "#fff"
          }
        },
        type: 'line',
        emphasis:{
          label: {
            show: true
          }
        }
      },
      {
        areaStyle: {
            // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //     {
            //     offset: 0,
            //     color: 'rgba(58,77,233,0.8)'
            //     },
            //     {
            //     offset: 1,
            //     color: 'rgba(58,77,233,0.3)'
            //     }
            // ])
            color: '#3adb73'
        },
        itemStyle : { normal: {label : {show: true, color: '#fff'}}},
        data: [],
        type: 'line',
        yAxisIndex: 1
      }
    ]
  };

export const barOption2 = {
    color: ['#fd5028', '#c6c6c6', ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      },
      textStyle:{
        align:'left'
      }
    },
    legend: {
      left: 'left',
      orient: 'vertical',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        formatter: function(value, index) {
          return index + 1
        }
      },
      data: []
    },
    yAxis: {
      type: 'value',
    },
    series: [
      
      {
        name: '已使用',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        // emphasis: {
        //   focus: 'series'
        // },
        data: []
      },
      {
        name: '未使用',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        // emphasis: {
        //   focus: 'series'
        // },
        data: []
      },
      
    ]
  };

export const Lineoption2 = {
  title: {
    text: '疫苗接种',
    textStyle: {
      fontSize: 12
    },
    right: 'right'
  },
  tooltip: {
    trigger: 'axis',
    textStyle:{
      align:'left'
    }
  },
  grid: {
      right: '20px',
      top: '40px',
      bottom: '12%'
  },
  legend: {
    data: [],
    left: 'left',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLabel: {
      interval:0,
    },
    data: []
  },
  yAxis: [
    {
      type: 'value',
    }
  ],
  series: [
    {
      
      itemStyle : { normal: {label : {show: false, color: '#fff'}}},
      data: [],
      type: 'line',
      smooth: 0.6,
      emphasis:{
        label: {
          show: true
        }
      }
    },
    {
      
      itemStyle : { normal: {label : {show: false, color: '#fff'}}},
      data: [],
      type: 'line',
      smooth: 0.6,
      emphasis:{
        label: {
          show: true
        }
      },
    }
  ]
};


export const barOption = {
  color: ['#F9E626', '#00EBA0', ],
  title: {
    text: '疫苗接种',
    textStyle: {
      fontSize: 12
    },
    right: 'right'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
    },
    textStyle:{
      align:'left'
    }
  },
  legend: {
    left: 'left',
    orient: 'horizontal',
  },
  grid: {
    top: '16%',
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      interval:0,
    },
    data: []
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      name: '已使用',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        position: 'inside',
      },
      // emphasis: {
      //   focus: 'series'
      // },
      data: []
    },
    {
      name: '未使用',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        position: 'top',
        color: '#fff'
      },
      // emphasis: {
      //   focus: 'series'
      // },
      data: []
    },
    
  ]
};

export const barOption3 = {
  color: ['#F9E626', '#00EBA0', ],
  title: {
    text: '疫苗接种',
    textStyle: {
      fontSize: 12
    },
    right: 'right'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
    },
    textStyle:{
      align:'left'
    }
  },
  legend: {
    left: 'left',
    orient: 'horizontal',
  },
  grid: {
    top: '16%',
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      interval:0,
    },
    data: []
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      name: '已使用',
      type: 'bar',
      label: {
        show: true,
        position: 'top',
        color: '#fff'
      },
      // emphasis: {
      //   focus: 'series'
      // },
      data: []
    },
    {
      name: '未使用',
      type: 'bar',
      label: {
        show: true,
        position: 'top',
        color: '#fff'
      },
      // emphasis: {
      //   focus: 'series'
      // },
      data: []
    },
    
  ]
};
