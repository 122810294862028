<template>
  <div class="left_con">
    <div class="top">
      <CustomCon :w="120" class="l" tText="指挥架构">
        <ul>
          <li @mousemove="mousemoveHandle($event, i)" @mouseleave="mouseleaveHandle"  v-for="(user, i) in userList" :key="'user' + i">
            {{user.name}}
            <div v-if="currentIndex === i" class="tip" :style="{left: pos.x + 'px', top: pos.y + 'px'}">
                <span class="t">负责人</span>
                <span>姓名： {{user.name}}</span><br /><span>性别： {{user.sex ? '男' : '女'}}</span><br /><span>职务： {{user.job}}</span><br /><span>电话： {{user.tel}}</span>
            </div>
          </li>
        </ul>
      </CustomCon>
      <CustomCon :w="140" class="r" tText="领导小组">
        <ul>
          <li @mousemove="mousemoveHandle2($event, team)" @mouseleave="mouseleaveHandle2"  v-for="(team, index) in teamList1" :key="'lingdao' + index">
            {{team.name}}
          </li>
          <div class="tip" :style="{left: pos2.x + 'px', top: pos2.y + 'px'}">
              <span class="t">成员</span>
              <div style="display:flex;">
                <div>组&nbsp;&nbsp;&nbsp;长：</div>
                <div>
                  <template v-for="(u, ix) in teammate">
                    <div v-if="u.type == 1" :key="ix"><span style="display:inline-block;width:55px;text-align: justify;text-align-last:justify;">{{u.name}}</span>  {{u.tel}}</div>
                  </template>
                </div>
              </div>
              <div style="display:flex;">
                <div>副组长：</div>
                <div>
                  <template v-for="(u, ix) in teammate">
                    <div v-if="u.type == 2" :key="ix"><span style="display:inline-block;width:55px;text-align: justify;text-align-last:justify;">{{u.name}}</span>  {{u.tel}}</div>
                  </template>
                </div>
              </div>
              <div style="display:flex;">
                <div>组&nbsp;&nbsp;&nbsp;员：</div>
                <div>
                  <template v-for="(u, ix) in teammate">
                    <div v-if="u.type == 0" :key="ix"><span style="display:inline-block;width:55px;text-align:justify;text-align-last:justify;">{{u.name}}</span> {{u.tel}}</div>
                  </template>
                </div>
              </div>
          </div>
        </ul>
        <!--<ul>
          <li>
            <span @mousemove="mousemoveHandle2($event, team)" @mouseleave="mouseleaveHandle2" class="item" v-for="(team, index) in teamList1" :key="index">
              <span>{{team.name}}</span>
              <span>(待命中)</span>
            </span>
          </li>
          <li>
            <span class="tit">救援队</span>
            <span @mousemove="mousemoveHandle2($event, team)" @mouseleave="mouseleaveHandle2" class="item" v-for="(team, index) in teamList2" :key="index">
              <span>{{team.name}}</span>
              <span>(待命中)</span>
            </span>
          </li>
          <div class="tip" :style="{left: pos2.x + 'px', top: pos2.y + 'px'}">
              <span class="t">成员</span>
              <template v-for="(u, i) in teammate">
                <div :key="i" v-if="u.type == 1">队长： <span>{{u.name}}</span></div>
                <div :key="i" v-if="u.type == 2">副队长： <span>{{u.name}}</span></div>
              </template>
              <div>队员： 
                <template v-for="(u, ix) in teammate">
                  <span v-if="u.type == 0" :key="ix">{{u.name + '、'}}</span>
                </template>
              </div>
              <span>成员数： {{teammate.length}}</span><br />
              <template v-for="(u, ix2) in teammate">
                <div :key="ix2" v-if="u.type == 1">联系电话： <span>{{u.tel}}</span></div>
              </template>
          </div>
        </ul>-->
      </CustomCon>
    </div>
    <div class="center_l">
      <CustomCon :w="136" tText="防疫队伍">
        <div style="height:415px;">
          <div class="zhuanye" >
            <div class="title1">八个工作组</div>
            <ul class="ul1">
              <li @mousemove="mousemoveHandle4($event, team3)" @mouseleave="mouseleaveHandle4"  v-for="(team3, index3) in teamList3" :key="'gongzuo' + index3">
                {{team3.name}}
              </li>
              <div class="tip" :style="{left: pos4.x + 'px', top: pos4.y + 'px'}">
                <span class="t">成员</span>

                <template v-for="(u2, i2) in teammate">
                  <div :key="'' + i2" v-if="u2.type == 1">
                    <div>
                        联络员： <span>{{u2.name}}</span>
                    </div>
                    <div  v-if="u2.unit">
                        单位： <span>{{u2.unit}}</span>
                    </div>
                    <div>
                        职务： <span>{{u2.job}}</span>
                    </div>
                    <div>
                        联系方式： <span>{{u2.tel}}</span>
                    </div>
                  </div>
                  <!--<div :key="'' + i2" v-if="u2.type == 2">
                    <div>
                        联络员： <span>{{u2.name}}</span>
                    </div>
                    <div>
                        电话： <span>{{u2.tel}}</span>
                    </div>
                  </div>-->
                </template>
              </div>
            </ul>
          </div>
          
          <div class="zhuanye" style="margin:0;padding-top:0;">
            <div class="title1">十二个工作专班</div>
            <ul class="ul2">
              <li @mousemove="mousemoveHandle3($event, team2)" @mouseleave="mouseleaveHandle3"  v-for="(team2, index2) in teamList2" :key="'fangyi' +index2">
                {{team2.name}}
              </li>
              <div class="tip" :style="{left: pos3.x + 'px', top: pos3.y + 'px'}">
                <span class="t">成员</span>
                <template v-for="(u2, i2) in teammate">
                  <div :key="'' + i2" >
                    <div>
                        联络员： <span>{{u2.name}}</span>
                    </div>
                    <div v-if="u2.unit">
                        单位： <span>{{u2.unit}}</span>
                    </div>
                    <div>
                        职务： <span>{{u2.job}}</span>
                    </div>
                    <div>
                        联系方式： <span>{{u2.tel}}</span>
                    </div>
                  
                  </div>
                </template>
              </div>
            </ul>
          </div>
        </div>
      </CustomCon>
    </div>
    <!--<div class="center_l">
      <CustomCon :w="136" tText="疫情概况">
        <div style="display:flex;">
          <ul class="ul3">
            <li>
              <span>风险地区：</span><span>无</span>
            </li>
            <li>
              <div>隔离场所：</div>
              <div class="secd">
                <span>常态化场所：</span><span>4个</span>
              </div>
              <div class="secd"><span>可征用场所：</span><span>10个</span></div>
            </li>
            <li>
              <span>核酸检测点：</span><span>93个</span>
            </li>
            <li>
              <span>疫苗接种点：</span><span>25个</span>
            </li>
          </ul>
           <ul class="ul3">
            <li >
              <div>物资储备：</div>
              <div class="secd" v-for="item in resources" :key="item.id">
                <span>{{item.name}}：</span><span>{{item.number}}{{item.unit}}</span>
              </div>
            </li>
          </ul>
        </div>
      </CustomCon>
    </div>-->
    <div class="center_l">
      <CustomCon :w="186" tText="防疫物资概况">
        <div style="height:110px;">
           <ul class="ul3">
            <!--<li >
              <div>物资储备：</div>
              <div class="secd" v-for="item in resources" :key="item.id">
                <span>{{item.name}}：</span><span>{{item.number}}{{item.unit}}</span>
              </div>
            </li>-->
            <li v-for="item in resources" :key="'res' + item.id">
              {{item.name}}：{{item.number}}{{item.unit}}
            </li>
          </ul>
        </div>
      </CustomCon>
    </div>
  </div>
</template>

<script>
import CustomCon from '../../components/custom-con.vue'
import {e1Option, pieOption} from '../../data/home'
export default {
  name: 'LeftCon',
  components: {
    CustomCon,
  },
  created() {
    this.getPeople();
    this.getTeam();
    this.getResources()
  },
  data() {
    return {
      e1Options: e1Option,
      e3Options: pieOption,
      userList: [],
      teamList1: [],
      teamList2: [],
      teamList3: [],
      pos: {
          x: -10000,
          y: -10000
      },
      pos2: {
          x: -10000,
          y: -10000
      },
      pos3: {
          x: -10000,
          y: -10000
      },
      pos4: {
          x: -10000,
          y: -10000
      },
      teammate: [],
      currentIndex: '',
      resources: []
    }
  },
  methods: {
    getPeople() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/leader',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
      ).then(res => {
        this.userList = res.data.data;
      })
    },
    getTeam() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/team',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
      ).then(res => {
        this.teamList1 = res.data.data.filter(el => el.type == 1);
        this.teamList2 = res.data.data.filter(el => el.type == 4);
        this.teamList3 = res.data.data.filter(el => el.type == 3);
      })
    },
    getResources() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/resources',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
      ).then(res => {
        this.resources = res.data.data;
      })
    },
    mousemoveHandle(e, i) {
      this.currentIndex = i;
      this.pos.x = e.pageX + 20;
      this.pos.y = e.pageY - 80;
    },
    mouseleaveHandle() {
      this.pos = {
          x: -10000,
          y: -10000
      }
      this.currentIndex = '';
    },
    mousemoveHandle2(e, item) {
      if (item.teammate.length > 0) {
        this.pos2.x = e.pageX - 160;
        this.pos2.y = e.pageY - 80;
        this.teammate = item.teammate;
      }
    },
    mouseleaveHandle2() {
      this.pos2 = {
          x: -10000,
          y: -10000
      }
    },
    mousemoveHandle3(e, item) {
      this.pos3.x = e.pageX + 20;
      this.pos3.y = e.pageY - 360;
      this.teammate = item.teammate;
    },
    mouseleaveHandle3() {
      this.pos3 = {
          x: -10000,
          y: -10000
      }
    },
    mousemoveHandle4(e, item) {
      this.pos4.x = e.pageX + 20;
      this.pos4.y = e.pageY - 365;
      this.teammate = item.teammate;
    },
    mouseleaveHandle4() {
      this.pos4 = {
          x: -10000,
          y: -10000
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left_con {
  margin-top: 15px;
  width: 570px;
  .top {
    height: 274px;
    display: flex;
    .l {
      width: 160px;
      margin-right: 14px;
      ul {
        margin-top: 40px;
        height: calc(100% - 50px);
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;
        overflow-y: auto;
        li {
          font-size: 24px;
          font-weight: 700;
          &:hover > .tip {
            display: block;
          }
        }
      }
    }
    .r {
     flex: 1;
      ul {
        margin-top: 36px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around !important;
        height: calc(100% - 50px);
        li {
          padding: 0 10px;
          display: flex;
          font-size: 24px;
          font-weight: 700;
          flex-direction: column;
          .tit {
            font-size: 20px;
            font-weight: 600;
          }
          .item {
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .center_l {
    width: 100%;
    margin: 15px 0;
    .e1 {
      height: 284px;
    }
    .zhuanye {
      margin-top: 23px;
      padding: 15px 10px 0 ;
      font-weight: 600;
      .title1 {
        text-align: center;
        font-size: 24px;
        box-sizing: border-box;
      }
      .ul1 {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px; 
        box-sizing: border-box;
        li {
          font-size: 18px;
          height: 30px;
          width: 25%;
          span {
            line-height: 38px;
            display: inline-block;
          }
        }
      }
      .ul2 {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px; 
        box-sizing: border-box;
        li {
          font-size: 18px;
          //height: 30px;
          padding-bottom:5px;
          width: 46%;
          span {
            line-height: 38px;
            display: inline-block;
          }
        }
        li:nth-child(2n) {
            padding-left: 4%;
        }
      }
    }
    
    .ul3 {
      padding: 15px 0 15px 10px; 
      box-sizing: border-box;
      //height: 252px;
      margin-top: 23px;
      display: flex;;
      flex-wrap: wrap;
      li {
        font-size: 18px;
        font-weight: 600;
        width: 50%;
        padding-bottom: 5px;
        &>span {
          line-height: 38px;
          display: inline-block;
          
        }
        .secd {
          text-indent: 0.5em;
          line-height: 38px;
          span {
            display: inline-block;
          }
        }
      }
    }
  }
}
.row-item {
  height: 10px !important;
}

.tip {
    border: 1px solid #00faf9;
    border-radius: 4px;
    background-color: #004259;
    padding: 15px;
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    left: -13000px;
    top: -15000px;
    color: #00faf9;
    width: 300px;
    z-index: 9999999999;
    &::after {
        content: '';
        display: inline-block;
        height: 0px;
        width: 0px;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #00faf9;
        position: absolute;
        left: -20px;
        top: 20px;
    }
    .t {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: -15px;
      padding: 1px 6px;
      transform: translateX(-50%);
      border: 1px solid #00faf9;
      background-color: #004259;
    }
}
</style>