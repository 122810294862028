import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/area-desc',
    name: 'areaDesc',
    component: () => import('../views/areaDesc.vue')
  },
  {
    path: '/process',
    name: 'Process',
    component: () => import('../views/process.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
