export default {
    "color": [
        "#00eba0",
        "#cd5359",
        "#264aff",
        "#f9e627",
        "#89995e",
        "#4252c5",
        "#2275a5",
        "#4a7f5f",
        "#478ef8",
    ],
    "backgroundColor": "rgba(0, 0, 0, 0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#00faf9"
        },
        "subtextStyle": {
            "color": "#00faf9"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#00faf9"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#eb5454",
            "color0": "#47b262",
            "borderColor": "#eb5454",
            "borderColor0": "#47b262",
            "borderWidth": 1
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#00faf9"
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaa"
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
            "#5470c6",
            "#91cc75",
            "#fac858",
            "#ee6666",
            "#73c0de",
            "#3ba272",
            "#fc8452",
            "#9a60b4",
            "#ea7ccc"
        ],
        "label": {
            "color": "#00faf9"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#000"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#000"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#00faf9"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#00faf9"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#00faf9"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#E0E6F1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#00faf9"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#00faf9"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#e0e6f1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#00faf9"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#00faf9"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#00faf9"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#00faf9"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#00faf9"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#E0E6F1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#00faf9"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#ccc",
                "width": 1
            },
            "crossStyle": {
                "color": "#ccc",
                "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#DAE1F5",
            "width": 2
        },
        "itemStyle": {
            "color": "#A4B1D7",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#A4B1D7",
            "borderColor": "#A4B1D7",
            "borderWidth": 1
        },
        "checkpointStyle": {
            "color": "#316bf3",
            "borderColor": "fff"
        },
        "label": {
            "color": "#A4B1D7"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#FFF"
            },
            "controlStyle": {
                "color": "#A4B1D7",
                "borderColor": "#A4B1D7",
                "borderWidth": 1
            },
            "label": {
                "color": "#A4B1D7"
            }
        }
    },
    "visualMap": {
        "color": [
            "#bf444c",
            "#d88273",
            "#f6efa6"
        ]
    },
    "dataZoom": {
        "handleSize": "undefined%",
        "textStyle": {}
    },
    "markPoint": {
        "label": {
            "color": "#00faf9"
        },
        "emphasis": {
            "label": {
                "color": "#00faf9"
            }
        }
    }
}