<template>
  <div class="home">
    <div class="header">
      <div class="h_t">
        <span class="zh">惠城区疫情防控指挥中心</span>
        <span class="en">HuiCheng District Epidemic Prevention and Control Command Center</span>
      </div>
    </div>
    <div class="content">
      <Left />
      <div class="center">
        <div class="time">{{time}}</div>
        <ul class="tab">
          <template v-for="(item, i) in tabList" >
            <li v-if="i != 2" @click="tabChange(i)" :class="i === tabIndex ? 'cur' : ''" :key="'tab' + i">{{item}}</li>
          </template>
        </ul>
        <div class="map_con">
          <Map ref="map" style="height: 715px; margin-bottom: 7.5px" optionType="map" :tabIndex="tabIndex"/>
          <div class="info" v-html="html">
            <!-- <div>隔离场所</div>
            <div>1、火车站、汽车站等交通站点 </div>
            <div>2、密闭场所（酒吧、ktv、网吧等）</div>
            <div>3、 流动人口密集区（自建房、出租屋等）</div> -->
          </div>
        </div>
      </div>

        <component v-bind:is="currentTabComponent"></component>

      <!--<Right />-->
    </div>
  </div>
</template>

<script>
import {e1Option, pieOption, Lineoption, barOption2} from '../data/home'
import Map from '../components/map.vue'
import {formatDate} from '../utils/formatDate'
import Left from './components/left.vue'
import Right from './components/right.vue'
import VaccinateRight2 from './components/Vaccinate2.vue'

export default {
  name: 'HomeView',
  components: {
    Map,
    Left,
    Right,
    VaccinateRight2
  },
  data() {
    return {
      tabList: ['人流密集点', '隔离场所', '物资点', '交通站点', '跨境转运点', '哨点', '核酸检测点', '疫苗接种点'],
      tabIndex: 0,
      e1Options: e1Option,
      e3Options: pieOption,
      barOption2,
      e7Options: JSON.parse(JSON.stringify(Lineoption)),
      config: {
        data: [
        ],
        columnWidth: [620],
        carousel: 'single',
        rowNum: 3,
        oddRowBGC: 'rgba(0,0,0,0)',
        evenRowBGC: 'rgba(0,0,0,0)',
      },
      time: '',
      timer: null,
      textMap: {},
      currentTabComponent: 'Right',
    }
  },
  computed: {
    html() {
      let item = this.textMap[this.tabIndex];
      return item ? item.text : ''
    }
  },
  mounted() {
    const tabIndex = this.$route.query.tabIndex;
    this.currentTabComponent = 'Right';
    if(tabIndex) {
      this.tabIndex = Number(tabIndex);
      this.$nextTick(() => {
        this.tabChange(this.tabIndex)
      })
    } else {
      this.tabChange(0)
    }
    this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
    this.timer = setInterval(() => {
      this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
    }, 1000)
    this.getDesc()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    tabChange(i) {
      this.tabIndex = i;
      this.currentTabComponent = 'Right';
      if(i == 2) {
        this.$refs.map.getLocaltion4(1)
      } else if(i == 1) {
        this.$refs.map.getLocaltion(1)
      } else if(i == 7) {
        this.$refs.map.getLocaltion3(1)
        this.currentTabComponent = 'VaccinateRight2';
      } else if(i == 3) {
        this.$refs.map.getTraffic(1)
      } else if(i == 4) {
        this.$refs.map.getTrain(1)
      } else if(i == 0) {
        // this.$refs.map.getLocaltion0(1)
        this.$refs.map.cleanPoint(1);
      } else {
        this.$refs.map.cleanPoint(1);
      }
    },
    getDesc() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/areaText',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
      ).then((res) => {
          this.textMap = res.data.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  background-image: url("../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  color: #00faf9;
  .header {
    height: 20px;
    background-image: url('../assets/img/h1.png');
    background-size: 100% 100%;
    position: relative;
    .h_t {
      height: 90px;
      width: 600px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('../assets/img/t1.png');
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .zh {
        font-size: 33px;
        font-weight: bold;
      }
      .en {
        font-size: 14px;
      }
    }
  }
  .content {
    display: flex;
    & > .center {
      flex: 1;
      margin-top: 90px;
      padding: 0 15px;
      .time {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
      ul {
        width: 768px;
        display: flex;
        height: 36px;
        border: 1px solid #00f8f7;
        margin: 15px auto 7.5px auto;
        li {
          height: 36px;
          width: 153px;
          line-height: 36px;
          text-align: center;
          font-weight: 700;
          font-size: 16px;
          border-right: 1px solid #00f8f7;
          cursor: pointer;
          
          &.cur {
            border: 1px solid #fff;
            box-sizing: border-box;
            background-color: #677fa0;
            color: #fff;
          }
        }
      }
      .map_con {
        position: relative;
        .info {
          position: absolute;
          left: 10px;
          top: 10px;
        }
      }
    }
  }
}
.row-item {
  height: 10px !important;
}

.tip {
    border: 1px solid #00faf9;
    border-radius: 4px;
    background-color: #004259;
    padding: 15px;
    position: absolute;
    font-size: 18px;
    left: -13000px;
    top: -15000px;
    color: #00faf9;
    width: 250px;
    z-index: 9999999999;
    &::after {
        content: '';
        display: inline-block;
        height: 0px;
        width: 0px;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #00faf9;
        position: absolute;
        left: -20px;
        top: 50px;
    }
    .t {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: -15px;
        padding: 1px 6px;
        transform: translateX(-50%);
        border: 1px solid #00faf9;
        background-color: #004259;
    }
}
</style>
