<template>
    <div class="map">
        <CustomCon :w="720" :showTitle="false" style="width:100%;" >
            <div id="huiZhouMap" class="huiZhou_map" :class="!option.customProps.isShowhuiZhouAreaMap ? 'show' : ''"></div>
        </CustomCon>
        <div class="tip" :style="{left: pos.x + 'px', top: pos.y + 'px'}">
            <span>指导员： 李某某</span><br /><span>捎点： 1个</span><br /><span>隔离点： 3个</span><br /><span>核酸监测点： 3个</span><br /><span>是否有风险： 否</span>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import CustomCon from '../components/custom-con.vue'
import {huiZhouMapJson, huiZhouEchartsOption3, huiZhouEchartsOption2, huiZhouEchartsOption4} from '../data/map';
import {deepClone} from '../utils/index';
export default {
    name: 'huiZhouMap',
    props: {
        mapPointData: {
            type: Object,
            default: () => ({440307: [{name: '惠城区', value: [114.460202,23.11499, {dc_id:123}]}]})
        },
        option: {
            type: Object,
            default: () => ({customProps: {field: 'name'}})
        },
        autoPlay: {
            type: Boolean,
            default: true
        },
        optionType: {
            type: String,
            default: 'map'
        },
        tabIndex: {
            type: Number,
            default: 0
        }
    },
    components: {
        CustomCon
    },
    watch: {
        optionType() {
            this.init();
            this.timerHandle();
        }
    },
    // computed: {
    // 	huiZhouAreaEchartsOption() {
    // 		const option = huiZhouAreaEchartsOption;
    // 		option.series[0].data = this.mapPointData[this.currentAreaCode] || [];
    // 		return option;
    // 	}
    // },
    data() {
        return {
            huiZhouMapJson,
            huiZhouEcharts: null,
            huiZhouAreaEcharts: null,
            autoIndex: 0,
            areaMapJson: {
                type: 'FeatureCollection',
                features: []
            },
            timer: null,
            isInitArea: false,
            currentAreaCode: 0,
            pos: {
                x: -10000,
                y: -10000
            },
            huiZhouEchartsOption3: deepClone(huiZhouEchartsOption3),
            huiZhouEchartsOption2: deepClone(huiZhouEchartsOption2),
            huiZhouEchartsOption4,
            governmentPointMap: {
                '芦洲镇' : [114.530738,23.371824],
                '横沥镇' : [114.621393,23.162301],
                '汝湖镇' : [114.465426,23.183474],
                '马安镇' : [114.48847,23.069326],
                '三栋镇' : [114.455009,22.997408],
                '小金口' : [114.426812,23.176472],
                '江北' : [114.422152,23.117972],
                '水口' : [114.471748,23.111505],
                '桥东' : [114.422155,23.093272],
                '桥西' : [114.417531,23.079738],
                '江南' : [114.396562,23.110197],
                '龙丰' : [114.397191,23.081482],
                '河南岸' : [114.426138,23.079581],
            },
            areaList: []
        }
    },
    async created() {
        await this.getAreaList()
    },
    mounted() {
        // let tabIndex = this.$route.query.tabIndex
        // // if(this.optionType === 'map') {
        // if(tabIndex == 3) {
        //     // this.init();
        //     this.$nextTick(() => {
        //         this.$parent.getWatcher();
        //     })
        // } else if(tabIndex == 2) {
        //     this.getLocaltion4()
        // } else if(tabIndex == 1) {
        //     this.getLocaltion()
        // } else if(tabIndex == 4) {
        //     this.getLocaltion2()
        // } else if(tabIndex == 5) {
        //     this.getLocaltion3()
        // } else if(tabIndex == 0) {
        //     this.getLocaltion0()
        // }
        
        
        // this.timerHandle();
        // this.autoPlay && this.initTimer();
        
        // this.initArea();
    },
    methods: {
        scalePoint(point, zoom) {
            this.huiZhouEchartsOption3.geo.zoom = zoom;
            this.huiZhouEchartsOption3.geo.center = point;
            this.init()
        },
        getAreaList() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/areaBase',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
            ).then(res => {
                this.areaList = res.data.data;
            })
        },
        getLocaltion(page) {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/isolation',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', type: 1, area: this.$route.query.areaName}}
            ).then(res => {
                // let list = res.data.data.filter(el => el.area === this.$route.query.areaName);
                this.$axios({
                    url: 'http://test.tianjingcn.cn/api/isolation',
                    method: 'post',
                    data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', type: 2, area: this.$route.query.areaName}}
                ).then(res2 => {
                    let allPoint = [...res.data.data, ...res2.data.data];
                    let pointList = allPoint.map(el => {
                        return ({
                            name: el.name,
                            value: [el.lon, el.lat],
                            room: el.room,
                            used: el.used,
                            contacts: el.contacts,
                            address: el.address,
                            tel: el.tel,
                            type: el.type
                        })
                    })
                    if(page !== 1) {
                        pointList.push({
                            government: this.governmentPointMap[this.$route.query.areaName],
                            value: this.governmentPointMap[this.$route.query.areaName]

                        })
                        
                    }
                    if(page == 1) {
                        pointList.length = 4
                        pointList.push({
                            name:'',
                            value: [114.389031,23.090847],
                            government: 'government'
                        }, {name: '芦洲镇',value: '123'})
                        this.huiZhouEchartsOption2.geo.tooltip = {
                            backgroundColor: '#004259',
                            borderColor: '#00faf9',
                            textStyle: {
                                color: '#00faf9',
                                fontWeight: 'bolder',
                                fontSize: 18,
                            },
                            formatter: (params) => {
                                let data = params.data;
                                return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' : `<span>${data.name}</span><br /><span>负责人： ${data.contacts}</span><br /><span>电话： ${data.tel}</span><br /><span>已使用房间： ${data.used}间</span><br /><span>未使用房间： ${data.room - data.used}间</span><br />`
                            }
                        }
                        // this.huiZhouEchartsOption2.series[0].itemStyle.color = '#CD5359'
                    }
                    // this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#CD5359'
                    this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = function(params) {
                        if(params.data.type == 1) {
                            return '#CD5359'
                        } else {
                            return '#fff'
                        }
                    }
                    this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = pointList;
                    this.$parent.pointList = pointList;
                    this.init();
                })
            })
        },
        // 核酸监测点
        getLocaltion2() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/hs_station',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                // let list = res.data.data.filter(el => el.area === this.$route.query.areaName);
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        code: el.code,
                        number: el.number,
                        cover: el.cover
                    })
                })
                pointList.push({
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]
                })
                this.huiZhouEchartsOption2.geo.tooltip = {
                    backgroundColor: '#004259',
                    borderColor: '#00faf9',
                    textStyle: {
                        color: '#00faf9',
                        fontWeight: 'bolder',
                        fontSize: 18,
                    },
                    formatter: (params) => {
                    let data = params.data;
                    return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' : `<span>${data.name}</span><br /><span>编号： ${data.code}</span><br /><span>常住人口： ${data.number}</span><br /><span>覆盖地区： ${data.cover}间</span><br />`
                }}
                this.huiZhouEchartsOption3.series[0].itemStyle.color = '#fff'
                this.huiZhouEchartsOption3.series[0].data = pointList;
                this.$parent.pointList = pointList;
                this.init();
            })
        },
        // 疫苗接种
        getLocaltion3(page) {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/vaccinate',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName, type: page == 1 ? 1 : 0}}
            ).then(res => {
                // let list = res.data.data.filter(el => el.area === this.$route.query.areaName);
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        leader: el.leader
                    })
                })
                pointList.push({
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]
                })
                if(page == 1) {
                    pointList.push({
                        name:'',
                        value: [114.389031,23.090847],
                        government: 'government'
                    })
                    this.huiZhouEchartsOption2.geo.tooltip = {
                        backgroundColor: '#004259',
                        borderColor: '#00faf9',
                        textStyle: {
                            color: '#00faf9',
                            fontWeight: 'bolder',
                            fontSize: 18,
                        },
                        formatter: (params) => {
                        let data = params.data;
                        return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' : this.structTip(data)
                    }}
                }
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#fff'
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = pointList;
                this.$parent.pointList = pointList;
                this.init();
            })
        },
        // 物质运输点
        getLocaltion4(page) {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/material',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                // let list = res.data.data.filter(el => el.area === this.$route.query.areaName);
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        contacts: el.contacts,
                        tel: el.tel
                    })
                })
                pointList.push({
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]

                })
                if(page == 1) {
                    pointList.push({
                        name:'',
                        value: [114.389031,23.090847],
                        government: 'government'
                    })
                    this.huiZhouEchartsOption2.geo.tooltip = {
                        backgroundColor: '#004259',
                        borderColor: '#00faf9',
                        textStyle: {
                            color: '#00faf9',
                            fontWeight: 'bolder',
                            fontSize: 18,
                        },
                        formatter: (params) => {
                        let data = params.data;
                        return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' :  `<span>${data.name}</span><br /><span>负责人： ${data.contacts}</span><br /><span>电话： ${data.tel}</span><br />`
                    }}
                    this.huiZhouEchartsOption2.series[0].itemStyle.color = '#fff'
                }
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#fff'
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = pointList;
                
                this.$parent.pointList = pointList;
                this.init();
            })
        },
        // 人流密集区
        getLocaltion0(page) {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/place',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        contacts: el.contacts,
                        tel: el.tel
                    })
                })
                pointList.push({
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]
                })
                if(page == 1) {
                    pointList.push({
                        name:'',
                        value: [114.389031,23.090847],
                        government: 'government'
                    })
                    this.huiZhouEchartsOption2.geo.tooltip = {
                        backgroundColor: '#004259',
                        borderColor: '#00faf9',
                        textStyle: {
                            color: '#00faf9',
                            fontWeight: 'bolder',
                            fontSize: 18,
                        },
                        formatter: (params) => {
                        let data = params.data;
                        return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' :  `<span>${data.name}</span><br /><span>负责人： ${data.contacts}</span><br /><span>电话： ${data.tel}</span><br />`
                    }}
                    this.huiZhouEchartsOption2.series[0].itemStyle.color = '#fff'
                }
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#fff'
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = pointList;
                this.$parent.pointList = pointList;
                this.init();
            })
        },
        // 跨境货车运输点
        getTrain(page) {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/train',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
            ).then(res => {
                // let list = res.data.data.filter(el => el.area === this.$route.query.areaName);
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        contacts: el.contacts,
                        tel: el.tel
                    })
                })
                pointList.push({
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]

                })
                if(page == 1) {
                    pointList.push({
                        name:'',
                        value: [114.389031,23.090847],
                        government: 'government'
                    })
                    this.huiZhouEchartsOption2.geo.tooltip = {
                        backgroundColor: '#004259',
                        borderColor: '#00faf9',
                        textStyle: {
                            color: '#00faf9',
                            fontWeight: 'bolder',
                            fontSize: 18,
                        },
                        formatter: (params) => {
                        let data = params.data;
                        return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' :  `<span>${data.name}</span><br /><span>负责人： ${data.contacts}</span><br /><span>电话： ${data.tel}</span><br />`
                    }}
                    this.huiZhouEchartsOption2.series[0].itemStyle.color = '#fff'
                }
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#fff'
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = pointList;
                
                this.$parent.pointList = pointList;
                this.init();
            })
        },
        // 交通站点
        getTraffic(page) {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/traffic',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
            ).then(res => {
                // let list = res.data.data.filter(el => el.area === this.$route.query.areaName);
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        contacts: el.contacts,
                        tel: el.tel
                    })
                })
                pointList.push({
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]

                })
                if(page == 1) {
                    pointList.push({
                        name:'',
                        value: [114.389031,23.090847],
                        government: 'government'
                    })
                    this.huiZhouEchartsOption2.geo.tooltip = {
                        backgroundColor: '#004259',
                        borderColor: '#00faf9',
                        textStyle: {
                            color: '#00faf9',
                            fontWeight: 'bolder',
                            fontSize: 18,
                        },
                        formatter: (params) => {
                        let data = params.data;
                        return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' :  `<span>${data.name}</span><br /><span>负责人： ${data.contacts}</span><br /><span>电话： ${data.tel}</span><br />`
                    }}
                    this.huiZhouEchartsOption2.series[0].itemStyle.color = '#fff'
                }
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#fff'
                this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = pointList;
                
                this.$parent.pointList = pointList;
                this.init();
            })
        },
        // 消除dian
        cleanPoint(page) {
            this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].data = page == 1 ? [
                {value: [114.389031,23.090847],
                government: 'government'}
            ] : [{
                    government: this.governmentPointMap[this.$route.query.areaName],
                    value: this.governmentPointMap[this.$route.query.areaName]
                }];
            this.$parent.pointList = [];
            if(page == 1) {
                this.huiZhouEchartsOption2.geo.tooltip = {
                    backgroundColor: '#004259',
                    borderColor: '#00faf9',
                    textStyle: {
                        color: '#00faf9',
                        fontWeight: 'bolder',
                        fontSize: 18,
                    },
                    formatter: (params) => {
                    let data = params.data;
                    return params.componentType == 'geo' ? this.structMapAreaTip(params) : data.government ? '惠城区人民政府' :  `<span>${data.name}</span><br /><span>负责人： ${data.contacts}</span><br /><span>电话： ${data.tel}</span><br />`
                }}
                this.huiZhouEchartsOption2.series[0].itemStyle.color = '#fff'
            }
            this[page == 1 ? 'huiZhouEchartsOption2' : 'huiZhouEchartsOption3'].series[0].itemStyle.color = '#fff'
            this.init();
        },
        init() {
            let mapJson = this.huiZhouMapJson;
            const areaName = this.$route.query.areaName;
            if(areaName) {
                const features = this.huiZhouMapJson.features.filter(el => el.properties.name === areaName);
                mapJson = {
                    "type": "FeatureCollection",
                    "features": features
                }
            }
            echarts.registerMap('huiZhou', mapJson);
            this.huiZhouEcharts = echarts.init(document.getElementById('huiZhouMap'));
            this.huiZhouEcharts.on('mousemove', () => {
                if(this.optionType === 'map2') return
                // this.pos.x = params.event.offsetX + 30;
                // this.pos.y = params.event.offsetY - 50;
                // if(!this.autoPlay) return;
                // window.clearInterval(this.timer)
            })
            this.huiZhouEcharts.on('mouseout', () => {
                // if(!this.autoPlay) return;
                // this.initTimer();
                this.pos = {
                    x: -10000,
                    y: -10000
                }
            })
            let options = (this.optionType === 'map' ? this.huiZhouEchartsOption2 : this.huiZhouEchartsOption3);
            this.huiZhouEcharts.on('click', (params) => {
                if(this.optionType !== 'map2' && !params.data) {
                    this.$router.push({
                        path: '/area-desc',
                        query: {
                            areaName: params.name,
                            tabIndex: this.tabIndex
                        }
                    })
                }
            })
            this.huiZhouEcharts.setOption(options, true);
        },
        initArea(areaName = '宝安区') {
            const areaJson = this.huiZhouMapJson.features.filter(el => el.properties.name === areaName);
            this.currentAreaCode = areaJson[0].properties.adcode;
            this.$emit('map-area-selected-change', areaJson[0].properties)
            this.areaMapJson.features = areaJson;
            echarts.registerMap('huiZhouArea', this.areaMapJson);
            this.huiZhouAreaEcharts = echarts.init(document.getElementById('huiZhouAreaMap'));
            this.huiZhouAreaEcharts.setOption(this.huiZhouAreaEchartsOption, true);
            if(this.isInitArea) return;
            this.huiZhouAreaEcharts.on('mousemove', () => {
                if(!this.autoPlay) return;
                window.clearInterval(this.timer)
            })
            this.huiZhouAreaEcharts.on('mouseout', () => {
                if(!this.autoPlay) return;
                this.initTimer();
            })
            this.huiZhouAreaEcharts.on('click', (params) => {
                if(!this.autoPlay) return;
                params.value && this.$emit('map-point-click', params.value);
            })
            this.isInitArea = true;
        },
        initTimer() {
            clearInterval(this.timer);
            this.timer = setInterval(this.timerHandle, this.option.customProps.interval || 3000);
        },
        timerHandle() {
            const options = (this.optionType === 'map' ? this.huiZhouEchartsOption2 : this.huiZhouEchartsOption3);
            // const item = options.series[0].data[this.autoIndex];
            // item.selected = true;
            this.huiZhouEcharts.setOption(options, true);
            this.autoIndex++;
            if(this.autoIndex >= 11) {
                this.autoIndex = 0;
            }
            // this.option.customProps.isShowhuiZhouAreaMap && this.initArea(item.name);
        },
        structTip(data) {
            let html = `<div>${data.name}</div>`;
            data.leader.forEach(el => {
                html += `<div>${el.type == 1 ? '组长' : el.type == 2 ? '常务副组长' : '副组长'}：${el.name} ${el.phone}</div>`
            })
            return html
        },
        structMapAreaTip(params) {
            let name = params.name;
            const area = this.areaList.filter(el => el.name == name);
            let html = `<div style="font-size:24;">${name}</div>`;
            area[0] && area[0].leader.forEach(el => {
                if (el.type == 1) {
                    html += `<div>书记: ${el.name} ${el.tel}</div>`;
                }
                 
            })
            area[0] && area[0].leader.forEach(el => {
                if (el.type == 2) {
                    html += `<div>主任: ${el.name} ${el.tel}</div>`;
                }
            })

            area[0] && area[0].leader.forEach(el => {
                if (el.type == 4) {
                    html += `<div>镇长: ${el.name} ${el.tel}</div>`;
                }
            })

            area[0] && area[0].leader.forEach(el => {
                if (el.type == 3) {
                    html += `<div>党政办负责人: ${el.name} ${el.tel}</div>`;
                }
            })
            
            return html;
        }
    },
}
</script>

<style lang="scss" scoped>
.map {
    display: flex;
    height: 100%;
    position: relative;
    .huiZhou_map {
        height: 100%;
        width: 100%;
        &.show {
            width: 100%;
        }
    }
    .tip {
        border: 1px solid #fff;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .4);
        padding: 15px;
        position: absolute;
        color: #fff;
        &::after {
            content: '';
            display: inline-block;
            height: 0px;
            width: 0px;
            border-left: 10px solid transparent;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #fff;
            position: absolute;
            left: -20px;
            top: 50px;
        }
    }
}
</style>