function padLeftZero (str) {
	return ('00' + str).substr(str.length);
}

export function formatDate (time, fmt) {
	if (time === null || time === undefined || time === '') {
		return "";
	}
	let date = new Date(time);
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds()
	};
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + '';
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
		}
	}
	return fmt;
}

export function getLastMonthYestdy (date) {
	date = date || new Date();
	let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	let strYear = date.getFullYear();
	let strDay = date.getDate();
	// 本月的月份
	let strMonth = date.getMonth() + 1;
	if (strYear % 4 === 0 && strYear % 100 !== 0) {
		daysInMonth[1] = 29;
	}
	if (strMonth === 1) {
		strYear -= 1;
		strMonth = 13;
	}
	strMonth -= 2;
	strDay = Math.min(strDay, daysInMonth[strMonth]);
	date.setFullYear(strYear);
	date.setDate(strDay);
	date.setMonth(strMonth);
	return date;
}

export function getLastWeekYestdy (date) {
	date = date || new Date();
	return new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
}
