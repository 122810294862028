<template>
  <div class="custom_con" >
    <img src="../assets/img/lt.png" class="lt" />
    <img src="../assets/img/lb.png" class="lb" />
    <img src="../assets/img/rt.png" class="rt" />
    <img src="../assets/img/rb.png" class="rb" />
    <img v-if="showTitle" src="../assets/img/title.png" :style="{width: w + 'px', height: h + 'px'}" class="title" />
    <span v-if="showTitle" class="t_text">{{tText}}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CustomCon',
  props: {
    w: {
      type: Number,
    },
    h: {
      type: Number,
    },
    tText: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  
}
</script>

<style scoped lang="scss">
.custom_con {
  min-width: 50px;
  min-height: 50px;
  position: relative;
  border: 1px solid #028ea2;
  margin: 2px;
  .lt{
    position: absolute;
    left: -7px;
    top: -6px;
  }
  .lb {
    position: absolute;
    left: -7px;
    bottom: -6px;
  }
  .rt{
    position: absolute;
    right: -7px;
    top: -6px;
  }
  .rb {
    position: absolute;
    right: -7px;
    bottom: -6px;
  }
  .title, .t_text {
    font-size: 18px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .t_text {
    font-weight: 700;
  }
}
</style>
