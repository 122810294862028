<template>
  <div class="right_con">
    <div>
      <CustomCon :w="136" tText="惠城疫情">
        <div class="rt1">
          <div class="time">数据截至 {{time}}</div>
          <div class="list">
            <div class="item">
              <span>累计确诊：</span>
              <span>{{infoData.leiji}}</span>
            </div>
            <div class="item">
              <span>现存确诊：</span>
              <span>{{infoData.quezhen}}</span>
            </div>
            <div class="item">
              <span>现存疑似：</span>
              <span>{{infoData.yishi}}</span>
            </div>
            <div class="item">
              <span>现存无症状：</span>
              <span>{{infoData.wuzhengzhuang}}</span>
            </div>
          </div>
        </div>
      </CustomCon>
    </div>
    
    <div class="rc">
      <CustomCon :w="156" tText="疫苗接种率" class="con c1" @click.native="handle(0)">
        <Echarts ref="e3Options1" class="e4" id="e4" :initStart="false" :options="e3Options1" />
      </CustomCon>
      <CustomCon :w="206" tText="60岁以上接种率" class="con c2" @click.native="handle(1)">
        <Echarts ref="e3Options2" class="e4" id="e5" :initStart="false" :options="e3Options2" />
      </CustomCon>
      <CustomCon :w="186" tText="18岁以下接种率" class="con c1" @click.native="handle(2)">
        <Echarts ref="e3Options3" class="e4" id="e60" :initStart="false" :options="e3Options3"/>
      </CustomCon>
      <CustomCon :w="176" tText="第三针接种率" class="con c2" @click.native="handle(3)">
        <Echarts ref="e3Options4" class="e4" id="e70" :initStart="false" :options="e3Options4" />
      </CustomCon>
    </div>
    <CustomCon :w="204" tText="隔离酒店房间数量" style="margin-top: 15px" @click.native="handle(4)">
      <Echarts ref="barOption2" style="height: 200px" id="e6" :options="barOption2" />
    </CustomCon>
    <CustomCon :w="330" tText="人员流动（外来/返惠人员）" style="margin-top: 15px" @click.native="handle(5)">
      <Echarts ref="e7" style="height: 200px" :initStart="false" id="e7" :options="e7Options" />
    </CustomCon>
    <el-dialog  :visible.sync="dialogVisible">
      <CustomCon :w="width" class="l" :tText="title" style="padding-top: 40px;">
        <div style="padding:20px;">
          <Echarts ref="e8" style="height: 400px" class="e4" id="e8" :initStart="false" :options="options" />
        </div>
        
      </CustomCon>
    </el-dialog>
  </div>
</template>

<script>
import CustomCon from '../../components/custom-con.vue'
import Echarts from '../../components/echarts.vue'
import {e1Option, pieOption, Lineoption, barOption2} from '../../data/home'
import {formatDate} from '../../utils/formatDate'
export default {
  name: 'RightCon',
  components: {
    CustomCon,
    Echarts,
  },
  data() {
    return {
      e1Options: e1Option,
      e3Options1: JSON.parse(JSON.stringify(pieOption)),
      e3Options2: JSON.parse(JSON.stringify(pieOption)),
      e3Options3: JSON.parse(JSON.stringify(pieOption)),
      e3Options4: JSON.parse(JSON.stringify(pieOption)),
      barOption2: barOption2,
      e7Options: JSON.parse(JSON.stringify(Lineoption)),
      infoData: {},
      chartData: {},
      dialogVisible: false,
      time: '',
      title: '',
      options: null,
      width: 200,
    }
  },
  created() {
    this.getEchartPeople();
    this.getInfo();
    this.getPieChart();
    this.getLocaltion();
    this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd')
  },
  methods: {
    getEchartPeople() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/areaCount',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
      ).then(res => {
        let time = res.data.data.map(el => el.time)
        let data1 = res.data.data.map(el => el.data.common)
        let data2 = res.data.data.map(el => el.data.danger)
        this.e7Options.series[0].data = data1;
        this.e7Options.series[1].data = data2;
        this.e7Options.xAxis.data = time;
        this.$refs.e7.initEcharts();
      })
    },
    getInfo() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/base_data',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
      ).then(res => {
        this.infoData = res.data.data;
      })
    },
    getPieChart() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/chart',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
      ).then(res => {
        let data = res.data.data;
        let that = this;
        this.e3Options1.series[0].data = [{name: '已接种', value: data.all.done}, {name: '未接种', value: data.all.limit}]
        this.e3Options2.series[0].data = [{name: '已接种', value: data['60'].done}, {name: '未接种', value: data['60'].limit}]
        this.e3Options3.series[0].data = [{name: '已接种', value: data['18'].done}, {name: '未接种', value: data['18'].limit}]
        this.e3Options4.series[0].data = [{name: '已接种', value: data.three.done}, {name: '未接种', value: data.three.limit}];
        this.e3Options1.legend = {
          orient: 'vertical',
          left: 'left',
          formatter: function (name) {
            //let total = 0;
            let tarValue;
            let p1data = that.e3Options1.series[0].data;
            for (let i=0, l = p1data.length; i < l; i++) {
              //total += p1data[i].value;
              if (p1data[i].name == name) {
                tarValue = p1data[i].value;
              }
            }
            // let p = ((tarValue / total)*100);
            return name  + " (" + tarValue + "人)";
          }
        },
        this.e3Options2.legend = {
          orient: 'vertical',
          left: 'left',
          formatter: function (name) {
            //let total = 0;
            let tarValue;
            let p1data = that.e3Options2.series[0].data;
            for (let i=0, l = p1data.length; i < l; i++) {
              //total += p1data[i].value;
              if (p1data[i].name == name) {
                tarValue = p1data[i].value;
              }
            }
            //let p = ((tarValue / total)*100);
            // return name  + " (" + p.toFixed(2) + "人)";
            return name  + " (" + tarValue + "人)";
          }
        },
        this.e3Options3.legend = {
          orient: 'vertical',
          left: 'left',
          formatter: function (name) {
            //let total = 0;
            let tarValue;
            let p1data = that.e3Options3.series[0].data;
            for (let i=0, l = p1data.length; i < l; i++) {
              //total += p1data[i].value;
              if (p1data[i].name == name) {
                tarValue = p1data[i].value;
              }
            }
            //let p = ((tarValue / total)*100);
            //return name  + " (" + p.toFixed(2) + "%)";
            return name  + " (" + tarValue + "人)";
          }
        },
        this.e3Options4.legend = {
          orient: 'vertical',
          left: 'left',
          formatter: function (name) {
            //let total = 0;
            let tarValue;
            let p1data = that.e3Options4.series[0].data;
            for (let i=0, l = p1data.length; i < l; i++) {
              //total += p1data[i].value;
              if (p1data[i].name == name) {
                tarValue = p1data[i].value;
              }
            }
            //let p = ((tarValue / total)*100);
            //return name  + " (" + p.toFixed(2) + "%)";
            return name  + " (" + tarValue + "人)";
          }
        },
        this.$refs.e3Options1.initEcharts()
        this.$refs.e3Options2.initEcharts()
        this.$refs.e3Options3.initEcharts()
        this.$refs.e3Options4.initEcharts()
      })
    },
    getLocaltion() {
      this.$axios({
          url: 'http://test.tianjingcn.cn/api/isolation',
          method: 'post',
          data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName || ''}}
      ).then(res => {
          let list = res.data.data;
          let nameList = [];
          let unusedList = [];
          let usedList = [];
          list.forEach(el => {
            nameList.push(el.name)
            unusedList.push(el.used)
            usedList.push(el.type == 2 ? (el.room - el.used) : {value: (el.room - el.used), itemStyle: {color: 'blue'}})
          });
          this.barOption2.xAxis.data = nameList;
          this.barOption2.series[0].data = unusedList;
          this.barOption2.series[1].data = usedList;
          this.$refs.barOption2.initEcharts()
      })
    },
    handle(number) {
      console.log(number);
      const title = ['疫苗接种率', '60岁以上接种率', '18岁以下接种率', '第三针接种率', '隔离酒店房间数量', '人员流动（外来/返惠人员）', ];
      this.title = title[number];
      this.dialogVisible = true;
      switch (number) {
        case 0: 
          this.options = this.e3Options1;
          this.width = 200
          break;
        case 1: 
          this.options = this.e3Options2;
          this.width = 200
          break;
        case 2: 
          this.options = this.e3Options3;
          this.width = 200
          break;
        case 3: 
          this.options = this.e3Options4;
          this.width = 200
          break;
        case 4: 
          this.options = this.barOption2;
          this.width = 300
          break;
        case 5: 
          this.options = this.e7Options;
          this.options.series[0].name = '总人数';
          this.options.series[1].name = '高风险';
          console.log(this.options);
          this.width = 400;
          break;
      }
      if (number < 4) {
        this.options.series[0].center = ['50%', '50%'];
          this.options.series[0].itemStyle.normal.label = {
            show: true,
            fontSize: 20,
            color: '#fff'
          };
      }
      this.$nextTick(() => {
        this.$refs.e8.initEcharts();
      })
      //this.$refs.e8.initEcharts();
     
    }
  }
}
</script>

<style lang="scss" scoped>
.right_con {
  margin-top: 15px;
  width: 550px;
  .rt1 {
    margin-top: 40px;
    .e3 {
      height: 200px;
      flex: 1;
    }
    .e_title {
      font-size: 22px;
    }
    .time {
      font-size: 18px;
      line-height: 18px;
      padding-left: 15px;
      font-weight: 700;
    }
    .list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 15px;
      .item {
        font-size: 20px;
        font-weight: 700;
        width: 50%;
        span {
          line-height: 40px;
          &:nth-child(1) {
            display: inline-block;
            padding-right: 30px;
          }
          &:nth-child(2) {
            color: #cd5359;
            font-size: 24px;
          }
        }
      }
    }
  }
  .rc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .con {
      margin-top: 15px;
      width: calc(50% - 8px);
      .e4 {
        flex: 1;
        height: 90px;
        margin-top: 40px;
      }
    }
  }
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 0;
  /*background-color: rgba(3, 27, 63, 0.8);*/
  background-color: #004259;
  color:#00faf9;
}
::v-deep .el-dialog__headerbtn {
  z-index: 9999;
}
</style>