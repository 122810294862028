<template>
	<div :id="id" ref="echart">
	</div>
</template>

<script>
// const echarts = require('echarts/lib/echarts');
// // require('echarts/lib/chart/pie');
// require('echarts/lib/chart/line');
// // require('echarts/lib/chart/bar');
// // require('echarts/lib/chart/tree');
// require('echarts/lib/component/tooltip');
// require('echarts/lib/component/legend');
import * as echarts from 'echarts'
import walden from '../data/echartTheme.js';
export default {
	name: 'ECharts',
	props: {
		id : [String, Number],
		options: {
			type: Object,
		},
		initStart: {
			type: Boolean,
			default: true
		}
	},
	data () {
		return {
			echarts: null,
		};
	},
	methods: {
		async initEcharts() {
			if(this.echarts === null) {
				echarts.registerTheme('walden', walden)
				this.echarts = echarts.init(document.getElementById(this.id), 'walden');
			}
			if(this.echarts) {
				this.$nextTick(() => {
					this.echarts.setOption(this.options, true);
					this.resize();
				})
			}
			/*let currentIndex = -1;
			const that = this;
			setInterval(function() {
				var dataLen = that.options.series[0].data.length;
				// 取消之前高亮的图形
				that.echarts.dispatchAction({
					type: 'downplay',
					seriesIndex: 0,
					dataIndex: currentIndex
				});
				currentIndex = (currentIndex + 1) % dataLen;
				// 高亮当前图形
				that.echarts.dispatchAction({
					type: 'highlight',
					seriesIndex: 0,
					dataIndex: currentIndex
				});
				// 显示 tooltip
				that.echarts.dispatchAction({
					type: 'showTip',
					seriesIndex: 0,
					dataIndex: currentIndex
				});
			}, 4000);*/
		},
		resize() {
			this.echarts && this.echarts.resize();
		}
	},
	created() {
		this.echartResizeEventHandler = () => {
			this.resize();
		}
	},
	mounted () {
		this.$nextTick(() => {
            this.initStart && this.initEcharts();
        })
	},
};
</script>

